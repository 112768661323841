<template>
  <form @submit.prevent="$emit('nextstep')" class="w-full">
    <h1>Procediamo. Inserisci le informazioni del tuo negozio</h1>
    <div class="grid grid-cols-6 gap-4 mt-8">
      <input
        type="text"
        class="col-span-6 my-2"
        placeholder="Ragione Sociale"
        v-model="s_companyname"
        name="s_companyname"
        autocomplete="off"
      />
      <input
        type="text"
        class="col-span-6 my-2"
        placeholder="P.IVA"
        v-model="s_vatnumber"
        name="s_vatnumber"
        autocomplete="off"
      />
      <input
        type="text"
        class="col-span-6 my-2"
        placeholder="Percentuale Cashback"
        v-model="s_cashback"
        name="s_cashback"
        autocomplete="off"
      />
      <input
        type="text"
        class="col-span-6 my-2 addpercent"
        placeholder="Indirizzo legale"
        v-model="s_legaladdress"
        name="s_legaladdress"
        autocomplete="off"
      />
      <!-- <input
        type="text"
        class="col-span-6 my-2"
        placeholder="Indirizzo operativo"
        v-model="s_operativeaddress"
        name="s_operativeaddress"
        autocomplete="off"
      /> -->
    </div>

    <div class="flex items-baseline mt-12">
      <button
        @click="$emit('prevstep')"
        class="text-secondary-500 w-full text-center"
      >
        <i class="mdi mdi-arrow-left"></i>
        Torna indietro
      </button>
      <button
        class="btn btn-cta w-full text-center"
        :disabled="$v.$invalid"
        :class="$v.$invalid ? ' cursor-not-allowed' : null"
      >
        Continua
      </button>
    </div>
  </form>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, numeric } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  computed: {
    ...mapState("createstore", [
      "companyname",
      "vatnumber",
      "cashback",
      "legaladdress",
      "operativeaddress"
    ]),
    s_companyname: {
      get() {
        return this.companyname;
      },
      set(v) {
        this.SET_COMPANYNAME(v);
      }
    },
    s_vatnumber: {
      get() {
        return this.vatnumber;
      },
      set(v) {
        this.SET_VATNUMBER(v);
      }
    },
    s_cashback: {
      get() {
        return this.cashback;
      },
      set(v) {
        this.SET_CASHBACK(v);
      }
    },
    s_legaladdress: {
      get() {
        return this.legaladdress;
      },
      set(v) {
        this.SET_LEGALADDRESS(v);
      }
    },
    s_operativeaddress: {
      get() {
        return this.operativeaddress;
      },
      set(v) {
        this.SET_OPERATIVEADDRESS(v);
      }
    }
  },
  validations: {
    s_companyname: { required, minLength: minLength(4) },
    s_vatnumber: { required, minLength: minLength(12), numeric },
    s_cashback: { required, minLength: minLength(1), numeric },
    s_legaladdress: { required, minLength: minLength(4) }
  },
  methods: {
    ...mapMutations("createstore", [
      "SET_COMPANYNAME",
      "SET_VATNUMBER",
      "SET_CASHBACK",
      "SET_LEGALADDRESS",
      "SET_OPERATIVEADDRESS"
    ])
  }
};
</script>
<style lang="postcss">
.addpercent::before {
  content: "%";
  color: red;
}
</style>
